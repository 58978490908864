import { defineStore } from 'pinia'
import { getChannelsREQFn, getSimpleChannelsREQFn, getAllTypeChannelsREQFn, delChannelREQFn, getChannelsDetailsFn, editChannelREQFn, searchChannelFn } from '@/api/message/channels'

export const useChannelInfo = defineStore('channelInfo', {
  state: () => ({
    channels: [],
    showAddEditDlg: false,
    showQrCodeDlg: false, // QRCode
    showImgCodeDlg: false, // 圖片的QRCode
    addEditDlgInfo: {},
    channelsLists: [], // 詳細渠道列表
    channelListTotal: 0, // 渠道清單總量
    activeChannelList: [], // 渠道簡單列表(不含已刪除)
    channelSimpleList: [], // 渠道簡單列表(含已刪除)
    currentChannel: 'web',
    thirdPartyFriendUrl: '',
    channelMap: new Map(),
    web: [],
    tg: [],
    whatsapp: [],
    messenger: [],
    instagram: [],
    line: []
  }),
  getters: {

  },
  actions: {
    async getChannelSimpleList () { // 取得渠道簡單列表-從緩存來-適合下拉使用
      const { $i18n } = useNuxtApp()
      const deletedText = $i18n.t('common.deleted')

      const params = {
        // type 類型 (空值或不餵: 不含刪除的渠道、 all: 全部含刪除的渠道)
        type: 'all'
      }
      const res = await getSimpleChannelsREQFn(params)
      const data = res.data.value
      if (data.code === 200) {
        const channelData = data.data
        channelData.forEach((item) => {
          if (item.isDeleted != null && item.isDeleted !== 0 && item.isDeleted !== '0') {
            item.channelName = item.channelName + '(' + deletedText + ')'
          }

          this.channelMap.set(item.id, item)
        })

        this.channelSimpleList = channelData

        this.activeChannelList = []
        this.channelSimpleList.forEach((i) => {
          if (i.isDeleted === 0 || i.isDeleted === '0') {
            this.activeChannelList.push(i)
          }
        })
      }
    },
    async getAllTypeChannels () { // 取得所有渠道類型列表，每次db撈取，不含已刪除的
      const res = await getAllTypeChannelsREQFn()
      const data = res.data.value
      if (data.code === 200) {
        const channelData = data.data
        channelData.forEach((item) => {
          this.channelMap.set(item.id, item)
        })
        this.activeChannelList = channelData
      }
    },
    async getChannelsList (type, pageNum, pageSize) { // 取得渠道列表，每次db撈取，根據渠道類型
      const paramsValue = {
        channelType: type,
        pageNum: pageNum || 1,
        pageSize: pageSize || 20
      }

      const res = await getChannelsREQFn(paramsValue)
      const data = res.data.value
      if (data.code === 200) {
        this[paramsValue.channelType] = data.data.data || []
      }
    },
    async delChannel (channelId, type) { // 刪除渠道
      const { $i18n } = useNuxtApp()
      const deletedSucText = $i18n.t('stores.channel.delSucMsg')

      const delParams = {
        channelId
      }
      const res = await delChannelREQFn(delParams)
      const data = res.data.value
      if (data.code === 200) {
        this.getChannelsList(type)
        ElMessage.success({ message: deletedSucText })
        return true
      }
    },
    async getChannelsDetails (url) { // 獲取渠道細節
      if (this.channels.length > 0) { return this.channels }
      const params = {
        url
      }
      const res = await getChannelsDetailsFn(params)
      const data = res.data.value
      if (data.code === 200) {
        this.channels = data.data
      }
      return this.channels
    },
    async editChannels (channelId, formData, channelUrl) { // 修改渠道
      const { $i18n } = useNuxtApp()
      const saveSucText = $i18n.t('stores.channel.saveSucMsg')

      const params = {
        channelId
      }
      const res = await editChannelREQFn(params, formData)
      const data = res.data.value
      if (data.code === 200) {
        ElMessage.success({ message: saveSucText })
        this.getChannelsDetails(channelUrl)
      }
    },
    async searchChannel (params) { // 搜尋渠道
      const res = await searchChannelFn(params)
      const data = res.data.value
      if (data.code === 200) {
        this[params.channelType] = data.data.data || []
      }
    }
  }
})
